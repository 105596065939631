@import url(https://fonts.googleapis.com/css?family=Racing+Sans+One|Roboto);
.logo-image {
    width: 35px;
    height: 35px;
  }
  .nav-item.nav-link {
    font-weight: 700;
  }

  /* Modify the backgorund color */ 
.navbar-custom { 
  background-color: #ffbf00;
} 

@media screen and (max-width: 768px) {
  .navbar-custom { 
    padding-left: 3px; 
    padding-right: 3px;
  } 
}

/* Modify brand and text color */ 
.navbar-custom .navbar-brand, 
.navbar-custom .navbar-text { 
    color: #ffbf00; 
}
.footer{
    background-color:#404040;
    color:white;
 }
 .footer p{
    margin-bottom: 0;
 }
 .footer a{
    color:white;
    padding-right:10px;
 }
 .footer a:hover{
    color:grey;
 }
.home-content{
  padding-top: 70px;
}

.home-content .container{
   margin-top:20px;
   margin-bottom:20px;
}

.home-content .container .row{
   margin-top:20px;
   margin-bottom:20px;
}

p.important.white{
   color:white;
   font-size:2em;
   font-weight: 700;
}

.card { 
   margin-bottom: 15px;
}

.image-fitted .image-gallery-image {
   max-width: 1078px;
   height: 607px;
   object-fit: cover;
}

.image-gallery-slide {
   cursor: unset;
}

.carousel-control-prev-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF0000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }
  
.carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF0000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.upload-preview {
   border-radius: 5px;
   margin-top: 5px;
   margin-left: 5px;
}

.btn {
   border-radius: 1.25rem;
}

.accessForm .form-control {
   color: #ffbf00;
  	font-size: 100%;
  	border: 1px solid transparent;
  	box-shadow: inset 0 1px 1px transparent;
   background-color: transparent;
  	border-bottom: 2px solid #ffbf00;
  	border-radius: 0;
  	padding-left: 0;
}

:focus {
  	outline: -webkit-focus-ring-color auto 0;
}

.form-control:focus {
  	box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
}

.accessForm {
	font-family:  'Roboto';
}

.accessForm h1,
.accessForm h2 {
	font-family: 'Racing Sans One', cursive;
}

.accessForm .form-control:focus {
  	border-color: transparent;
  	border-bottom: 2px solid #9c9e9f;
}

.preview-fitted {
   max-height: 150px;
   object-fit: cover;
}

@media screen and (max-width: 768px) {
   .image-fitted .image-gallery-image{
      height: 237px;
   }
}

textarea {
   resize: none;
}

.char-counter {
   font-size: 12px;
   margin-top: -1px;
   margin-right: 1px;
}

.main-box{
   /*padding-top: 100px;*/
   padding-top: 10%;
   margin-bottom: 30px;
   min-height: 70vh;
}

.main-box-for-semicerchio{
   margin-bottom: 30px;
   min-height: 70vh;
   margin-top: -250px;
}

/*@media screen and (max-width: 1080px) {
   .main-box{
      padding-top: 50%;
   }
}*/

@media screen and (max-width: 768px) {
   .main-box{
      padding-top: 30%;
   }

   .main-box-for-semicerchio{
      margin-top: -290px;
   }
}

.custom-file-label::after{
   content: "Scegli" !important;
}

.accordion_content{
   position: relative;
}

.accordion_content-resize{
   height: 254px;
   overflow: auto;
}

.custom_scroll::-webkit-scrollbar {
   width: 5px;               /* width of the entire scrollbar */
}
 
.custom_scroll::-webkit-scrollbar-track {
   background: rgba(124, 124, 124, 0.486);        /* color of the tracking area */
   border-radius: 25px;
}
 
.custom_scroll::-webkit-scrollbar-thumb {
   background-color: #3300ff;    /* color of the scroll thumb */
   border-radius: 25px;       /* roundness of the scroll thumb */
   /* border: 3px solid orange; */  /* creates padding around scroll thumb */
}

.dropdown-item:active{
   background-color: #3300ff;
}

.racemood-brand-font{
   font-family: 'Racing Sans One', cursive;
}

.center-brand {
   transform: translateX(-50%);
   left: 50%;
   position: absolute;
}

@media screen and (max-width: 768px) {
   .center-brand {
      transform: none;
      left: unset;
      padding-left: 5px;
   }
}

.header-menu-margins {
   margin-right: auto;
}

@media screen and (max-width: 768px) {
   .header-menu-margins {
      margin-left: auto;
   }
}

@media screen and (max-width: 768px) {
   .user-header-menu {
      position: absolute;
      right: 0;
   }
}

.guest-user-menu {
   padding: 10px;
   font-size: 25px;
   position: absolute;
   right: 0;
   top: 5px;
}

.guest-user-menu > a {
   color: black;
}

.form-title {
   text-align: center;
}

.like-button{
   opacity: 0;
   position: absolute;
}

.form-control:focus {
   border-color: #3300ff;
}

.adv{
   background-color: #adff2fad;
}

#for-adv {
   margin: 0;
}

.footer {
   bottom: 0;
   width: 100%;
   height: 100%;
   line-height: 30px;
   background-color: #1D1D1DFF;
   border-top-left-radius: 50% 50px;
   border-top-right-radius: 50% 50px;
}

.footer-titles {
   font-size: 20px;
   font-weight: 700;
   line-height: 1.2;
   color: #fff;
}

.footer-container {
   display: flex;
   flex-wrap: wrap;
}

.footer-item {
   width: 100%
}

.footer-container > div {
   flex: 50% 1;
}

.footer a {
   padding: 0;
}

.socials {
   font-size: 20px;
}

.item-centered {
   display: flex;
   justify-content: center;
}

.footer-font-color {
   color: #ffbf00 !important;
}

.closed-card {
   max-height: 33px;
   overflow: hidden;
}

.opened-card {
   overflow: auto;
   max-height: 228px;
}

.btn-link.btn:focus {
   box-shadow: none;
}

.menu-active {
   background-color: #3300ff;
   width: 100%;
   height: 4px;
   margin-top: 4px;
   border-radius: 10px;
}

.banner_to_sell {
   margin-top: 25px;
   display: flex;
   width: 100%;
   border: 3px solid;
   border-color: greenyellow;
   background-color: rgba(172, 255, 47, 0.267);
   border-radius: 30px;
   justify-content: center;
   word-break: break-all;
   line-height: 1;
}

.breakline {
   height: 30px;
}

.banner_to_sell_content {
   margin: 50px 0 50px 0;
   line-height: 20px;
   text-transform: uppercase;
   font-weight: bold;
   text-align: center;
}

.octane-like {
   width: 210px;
}

.btn-primary {
   color: #fff;
   background-color: #3300ff;
   border-color: #3300ff;
}

.btn-primary:hover {
   color: #fff;
   background-color: #3300ff;
   border-color: #3300ff;
}

.disc-name-color {
   color: #3300ff;
}

.title-login {
   font-size: 25px;
   text-align: center;
   color: #afafaf;
}

.subtitle-login {
   font-size: 15px;
   color: grey;
   text-align: center;
}

.footer-help {
   margin-top: -7px;
}

.heading-404 {
   font-size: 50px;
   text-align: center;
}

.image404-wrapper {
   margin: 70px 0;
}

a:hover {
   text-decoration: none;
}
 
.video-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0,0,0,.4);
}

.accessForm .form-control:-ms-input-placeholder {
   color: #ffbf00dc;
}

.accessForm .form-control::placeholder {
   color: #ffbf00dc;
}

#backVideo {
   position: fixed;
   width: 100%;
}

@media screen and (max-width: 992px){
   #backVideo {
      position: fixed;
      width: auto;
      height: 100%;
   }
}

@media screen and (max-width: 768px){
   #backVideo {
      position: fixed;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate(-41%, -50%);
   }
}

/* 
* puts image background on login (check register) 
@media screen and (max-width: 768px){
   .main {
      background-image: url('../../images/bg-image-light.png');
      height: 100%;
      background-position: center;
      background-size: cover;
      position: fixed;
   }
} */

.alert-danger,
.alert-success {
   padding: 10px 15px;
   margin: 13px 0px;
}

.alert-danger {
   background-color: #f8d7dac2;
   border-color: #f5c6cb94;
}

.potential-container {
   font-family: 'Racing Sans One', cursive;
   margin-bottom: 15px;
}

.display-cv { 
   display: flex;
   align-items: center;
   justify-content: center;
   color: #07c11e;
   font-size: 30px;
   line-height: 25px;
}

.cv-title {
   display: flex;
   justify-content: center;
   margin-top: 20px;
   font-size: 25px;
   grid-gap: 5px;
   gap: 5px;
}

.info-cv-icon {
   font-size: 13px;
   color: grey;
}

.cv-font {
   color: #07c11e;
   font-size: 30px;
}

.category-mod-item {
   width: 50%;
   color: grey;
   padding: 10px;
}
 
.mods-container {
   display: flex;
   flex-wrap: wrap;
}

.mod-item {
   color: black;
   display: list-item;
   list-style: circle;
}

.ul-mods {
   padding: revert;
   display: grid;
}

.cat-logos-size {
   width: 30px;
   height: 30px;
   margin-right: 10px;
}

.mod-category-title {
   display: flex;
   align-items: center;
}

.supplier-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 20px;
}

.supplier-name {
   font-family: 'Racing Sans One';
   font-size: 30px;
   line-height: 33px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.supplier-name a {
   color: black;
}

.divider {
   width: 50%;
   border-top: 1px solid #dee2e6;
}

.separator {
   display: flex;
   align-items: center;
   text-align: center;
   width: 50%;
}
 
.separator::before,
.separator::after {
   content: '';
   flex: 1 1;
   border-bottom: 1px solid black;
}

.separator:not(:empty)::before {
   margin-right: 10px;
}

.separator:not(:empty)::after {
   margin-left: 10px;
}

.username-menu {
   max-width: 230px;
   display: flex;
   flex-direction: row;
   align-items: center;
}

@media screen and (max-width: 768px) {
   .username-menu {
      max-width: 110px;
   }
}

.username {
   overflow: hidden;
   text-overflow: ellipsis;
}

.wrapper-stima-cv {
   margin-right: 20px;
}

@media screen and (max-width: 768px) {
   .wrapper-stima-cv {
      margin-right: 2px;
   }
}

@media screen and (max-width: 768px) {
   .btn-stima-cv {
      font-size: 15px;
   }
}

.approver-actions {
   display: flex;
   margin: 15px 0;
   grid-gap: 10px;
   gap: 10px;
   justify-content: center;
}

.styles-module_tooltip__mnnfp {
   width: 70% !important;
}

.styles-module_tooltip__mnnfp li {
   display: list-item;
   list-style: disc;
   padding: 0;
}

.styles-module_tooltip__mnnfp ul {
   padding-left: 28px;
}

.insta_name_wrapper{
   display: flex;
   justify-content: center;
}

.insta_name_input {
   width: 60%;
   margin-top: 20px;
}

.pad_top_404 {
   padding-top: 100px;
}

.semicerchio {
   background-color: #ffbf00;
   width: 100%;
   height: 400px;
   border-bottom-left-radius: 50% 100px;
   border-bottom-right-radius: 50% 100px;
   position: relative;
}

select {
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-login-wrapper {
   display: flex;
   justify-content: center;
}

/* FIX ALL'APERTURA MODALI SI SPOSTAVANO ALCUNI OGGETTI*/
body {
   overflow: auto !important;
   padding: 0 !important;
}

nav.navbar.fixed-top {
   padding-right: 1rem !important;
}

@media screen and (max-width: 768px) {
   nav.navbar.fixed-top {
      padding-right: 3px !important;
   }
}

/* FINE FIX*/

.mods-container.empty {
   display: flex;
   justify-content: center;
}

.login-to-show-elab {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
}

.btn-as-link {
   background: none;
   border: none;
}

.hp-title {
   font-weight: 300;
   font-size: 30px;
}

.hp-title-bold {
   font-weight: 500;
}

.hp-subtitle {
   font-weight: 400;
}

@media screen and (max-width: 768px) {
   body {
      font-family: unset;
   }

   .dropdown-menu-right {
      right: 3px;
   }
}
.image-gallery-icon{
    color:#fff;
    transition:all .2s ease-out;
    -webkit-appearance:none;
            appearance:none;
    background-color:transparent;
    border:0;
    cursor:pointer;
    outline:0;
    position:absolute;
    z-index:4;
    filter:drop-shadow(0 2px 2px #1a1a1a)
}
@media (min-width:768px){
    .image-gallery-icon:hover{
        color:#ffbf00
    }
    .image-gallery-icon:hover .image-gallery-svg{
        transform:scale(1.1)
    }
}
.image-gallery-icon:focus{
    outline:2px solid #ffbf00
}
.image-gallery-using-mouse .image-gallery-icon:focus{
    outline:0
}
.image-gallery-fullscreen-button,.image-gallery-play-button{
    bottom:0;
    padding:20px
}
.image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{
    height:36px;
    width:36px
}
@media (max-width:768px){
    .image-gallery-fullscreen-button,.image-gallery-play-button{
        padding:15px
    }
    .image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{
        height:24px;
        width:24px
    }
}
@media (max-width:480px){
    .image-gallery-fullscreen-button,.image-gallery-play-button{
        padding:10px
    }
    .image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{
        height:16px;
        width:16px
    }
}
.image-gallery-fullscreen-button{
    right:0
}
.image-gallery-play-button{
    left:0
}
.image-gallery-left-nav,.image-gallery-right-nav{
    /* padding:50px 10px; */
    top:50%;
    transform:translateY(-50%)
}
.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg{
    height:120px;
    width:60px
}
@media (max-width:768px){
    .image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg{
        height:72px;
        width:36px
    }
}
@media (max-width:480px){
    .image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg{
        height:48px;
        width:24px
    }
}
.image-gallery-left-nav[disabled],.image-gallery-right-nav[disabled]{
    cursor:disabled;
    opacity:.6;
    pointer-events:none
}
.image-gallery-left-nav{
    left:0
}
.image-gallery-right-nav{
    right:0
}
.image-gallery{
    -webkit-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
    -webkit-tap-highlight-color:transparent;
    position:relative;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.image-gallery.fullscreen-modal{
    background:#000;
    bottom:0;
    height:100%;
    left:0;
    position:fixed;
    right:0;
    top:0;
    width:100%;
    z-index:5
}
.image-gallery.fullscreen-modal .image-gallery-content{
    top:50%;
    transform:translateY(-50%)
}
.image-gallery-content{
    position:relative;
    line-height:0;
    top:0
}
.image-gallery-content.fullscreen{
    background:#000
}
/* .image-gallery-content .image-gallery-slide .image-gallery-image{
    max-height:calc(100vh - 80px)
} */
.image-gallery-content.left .image-gallery-slide .image-gallery-image,.image-gallery-content.right .image-gallery-slide .image-gallery-image{
    max-height:100vh
}
.image-gallery-slide-wrapper{
    position:relative
}
.image-gallery-slide-wrapper.left,.image-gallery-slide-wrapper.right{
    display:inline-block;
    width:calc(100% - 110px)
}
@media (max-width:768px){
    .image-gallery-slide-wrapper.left,.image-gallery-slide-wrapper.right{
        width:calc(100% - 87px)
    }
}
.image-gallery-slide-wrapper.image-gallery-rtl{
    direction:rtl
}
.image-gallery-slides{
    line-height:0;
    overflow:hidden;
    position:relative;
    white-space:nowrap;
    text-align:center
}
.image-gallery-slide{
    left:0;
    position:absolute;
    top:0;
    width:100%
}
.image-gallery-slide.center{
    position:relative
}
.image-gallery-slide .image-gallery-image{
    width:100%;
    object-fit:contain
}
.image-gallery-slide .image-gallery-description{
    background:rgba(0,0,0,.4);
    bottom:70px;
    color:#fff;
    left:0;
    line-height:1;
    padding:10px 20px;
    position:absolute;
    white-space:normal
}
@media (max-width:768px){
    .image-gallery-slide .image-gallery-description{
        bottom:45px;
        font-size:.8em;
        padding:8px 15px
    }
}
.image-gallery-bullets{
    bottom:20px;
    left:0;
    margin:0 auto;
    position:absolute;
    right:0;
    width:80%;
    z-index:4
}
.image-gallery-bullets .image-gallery-bullets-container{
    margin:0;
    padding:0;
    text-align:center
}
.image-gallery-bullets .image-gallery-bullet{
    -webkit-appearance:none;
            appearance:none;
    background-color:transparent;
    border:1px solid #fff;
    border-radius:50%;
    box-shadow:0 1px 0 #1a1a1a;
    cursor:pointer;
    display:inline-block;
    margin:0 5px;
    outline:0;
    padding:5px;
    transition:background .2s ease-out
}
@media (max-width:768px){
    .image-gallery-bullets .image-gallery-bullet{
        margin:0 3px;
        padding:3px
    }
}
@media (max-width:480px){
    .image-gallery-bullets .image-gallery-bullet{
        padding:2.7px
    }
}
.image-gallery-bullets .image-gallery-bullet:focus,.image-gallery-bullets .image-gallery-bullet:hover{
    background:#ffbf00;
    transform:scale(1.1)
}
.image-gallery-bullets .image-gallery-bullet.active{
    background:#fff
}
.image-gallery-thumbnails-wrapper{
    position:relative
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl{
    direction:rtl
}
.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{
    display:inline-block;
    vertical-align:top;
    width:100px
}
@media (max-width:768px){
    .image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{
        width:81px
    }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails{
    height:100%;
    width:100%;
    left:0;
    padding:0;
    position:absolute;
    top:0
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail{
    display:block;
    margin-right:0;
    padding:0
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail{
    margin-left:0;
    margin-top:2px
}
.image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{
    margin:0 5px
}
@media (max-width:768px){
    .image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{
        margin:0 3px
    }
}
.image-gallery-thumbnails{
    overflow:hidden;
    padding:5px 0
}
@media (max-width:768px){
    .image-gallery-thumbnails{
        padding:3px 0
    }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container{
    cursor:pointer;
    text-align:center;
    transition:transform .45s ease-out;
    white-space:nowrap
}
.image-gallery-thumbnail{
    display:inline-block;
    border:4px solid transparent;
    transition:border .3s ease-out;
    width:100px;
    background:0 0;
    padding:0
}
@media (max-width:768px){
    .image-gallery-thumbnail{
        border:3px solid transparent;
        width:81px
    }
}
.image-gallery-thumbnail+.image-gallery-thumbnail{
    margin-left:2px
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner{
    position:relative
}
.image-gallery-thumbnail .image-gallery-thumbnail-image{
    vertical-align:middle;
    width:100%;
    line-height:0
}
.image-gallery-thumbnail.active,.image-gallery-thumbnail:focus,.image-gallery-thumbnail:hover{
    outline:0;
    border:4px solid #ffbf00
}
@media (max-width:768px){
    .image-gallery-thumbnail.active,.image-gallery-thumbnail:focus,.image-gallery-thumbnail:hover{
        border:3px solid #ffbf00
    }
}
.image-gallery-thumbnail-label{
    box-sizing:border-box;
    color:#fff;
    font-size:1em;
    left:0;
    line-height:1em;
    padding:5%;
    position:absolute;
    top:50%;
    text-shadow:1px 1px 0 #000;
    transform:translateY(-50%);
    white-space:normal;
    width:100%
}
@media (max-width:768px){
    .image-gallery-thumbnail-label{
        font-size:.8em;
        line-height:.8em
    }
}
.image-gallery-index{
    background:rgba(0,0,0,.4);
    color:#fff;
    line-height:1;
    padding:10px 20px;
    position:absolute;
    right:0;
    top:0;
    z-index:4
}
@media (max-width:768px){
    .image-gallery-index{
        font-size:.8em;
        padding:5px 10px
    }
}
li,ul{
    padding:0;
    margin:0;
    list-style:none
}
li{
    padding:3px 0;
    display:inline-block
}
label{
    margin-left:5px
}
.app-header{
    letter-spacing:1px;
    text-transform:uppercase
}
.play-button{
    cursor:pointer;
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    margin:auto;
    height:60px;
    width:100px;
    background-color:rgba(0,0,0,.7);
    border-radius:5px
}
.play-button:hover{
    background-color:rgba(0,0,0,.9)
}
.play-button:after{
    content:"";
    display:block;
    position:absolute;
    top:16.5px;
    left:40px;
    margin:0 auto;
    border-style:solid;
    border-width:12.5px 0 12.5px 20px;
    border-color:transparent transparent transparent rgba(255,255,255,1)
}
.close-video::before{
    content:'✖';
    cursor:pointer;
    position:absolute;
    right:0;
    top:0;
    font-size:20px;
    padding:20px;
    z-index:1;
    line-height:.7;
    display:block;
    color:#fff
}
.video-wrapper{
    position:relative;
    padding:33.35% 0;
    height:0
}
.video-wrapper iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%
}
.app .image-gallery,.app-sandbox{
    margin:0 auto;
    width:65%;
    transition:all 1s ease
}
@media (max-width:1320px){
    .app-sandbox-content{
        padding:0 20px
    }
}
.app-sandbox{
    margin:40px auto;
    -webkit-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none
}
.app-buttons li{
    display:block
}
@media (max-width:768px){
    .app-header{
        font-size:20px
    }
    .app-buttons li{
        display:block;
        margin:10px 0
    }
    .app-buttons li+li{
        padding:0
    }
    .play-button{
        height:40px;
        width:65px
    }
    .play-button:after{
        top:11px;
        left:27px;
        border-width:8.5px 0 8.5px 12px
    }
    .close-video::before{
        font-size:16px;
        padding:15px
    }
}
@media (max-width:1024px){
    .app .image-gallery,.app-sandbox{
        width:100%
    }
}
.app-interval-input-group{
    display:table
}
.app-interval-label{
    display:table-cell;
    vertical-align:middle;
    padding:6px 12px;
    font-size:14px;
    font-weight:400;
    line-height:1;
    color:#555;
    text-align:center;
    background-color:#eee;
    border:3px solid #ccc;
    border-right:none;
    border-radius:4px;
    border-top-right-radius:0;
    border-bottom-right-radius:0
}
.app-interval-input{
    -webkit-appearance:none;
    display:table-cell;
    margin:0;
    padding:9px;
    border-radius:5px;
    font-size:14px;
    border:3px solid #ccc;
    background:#fff;
    width:100%;
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
input.app-interval-input{
    width:65px
}
.app-checkboxes{
    margin-top:10px
}
.app-checkboxes li{
    display:block
}
 
.stages-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-top: 30px;
}

.single-stage-container {
    width: 100%;
}

.mods-list {
    padding-left: 10px;
    padding-right: 10px;
}

.table-text-align {
    text-align: center;
    vertical-align: middle !important;
}

.elab-container {
    border: 1px solid #8080805e;
    border-radius: 5px;
    padding: 0 15px 15px 15px;
    margin-top: 20px;
}

.logo-image {
    width: 35px;
    height: 35px;
  }
  .nav-item.nav-link {
    font-weight: 700;
  }

  /* Modify the backgorund color */ 
.navbar-custom { 
  background-color: #ffbf00;
} 

@media screen and (max-width: 768px) {
  .navbar-custom { 
    padding-left: 3px; 
    padding-right: 3px;
  } 
}

/* Modify brand and text color */ 
.navbar-custom .navbar-brand, 
.navbar-custom .navbar-text { 
    color: #ffbf00; 
}
.home-content{
  padding-top: 70px;
}

.home-content .container{
   margin-top:20px;
   margin-bottom:20px;
}

.home-content .container .row{
   margin-top:20px;
   margin-bottom:20px;
}

p.important.white{
   color:white;
   font-size:2em;
   font-weight: 700;
}

.card { 
   margin-bottom: 15px;
}

.image-fitted .image-gallery-image {
   max-width: 1078px;
   height: 607px;
   object-fit: cover;
}

.image-gallery-slide {
   cursor: unset;
}

.carousel-control-prev-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF0000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }
  
.carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF0000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.upload-preview {
   border-radius: 5px;
   margin-top: 5px;
   margin-left: 5px;
}

.btn {
   border-radius: 1.25rem;
}

@import url('https://fonts.googleapis.com/css?family=Racing+Sans+One|Roboto');

.accessForm .form-control {
   color: #ffbf00;
  	font-size: 100%;
  	border: 1px solid transparent;
  	box-shadow: inset 0 1px 1px transparent;
   background-color: transparent;
  	border-bottom: 2px solid #ffbf00;
  	border-radius: 0;
  	padding-left: 0;
}

:focus {
  	outline: -webkit-focus-ring-color auto 0;
}

.form-control:focus {
  	-webkit-box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
  	box-shadow: inset 0 1px 1px transparent, 0 0 8px transparent;
}

.accessForm {
	font-family:  'Roboto';
}

.accessForm h1,
.accessForm h2 {
	font-family: 'Racing Sans One', cursive;
}

.accessForm .form-control:focus {
  	border-color: transparent;
  	border-bottom: 2px solid #9c9e9f;
}

.preview-fitted {
   max-height: 150px;
   object-fit: cover;
}

@media screen and (max-width: 768px) {
   .image-fitted .image-gallery-image{
      height: 237px;
   }
}

textarea {
   resize: none;
}

.char-counter {
   font-size: 12px;
   margin-top: -1px;
   margin-right: 1px;
}

.main-box{
   /*padding-top: 100px;*/
   padding-top: 10%;
   margin-bottom: 30px;
   min-height: 70vh;
}

.main-box-for-semicerchio{
   margin-bottom: 30px;
   min-height: 70vh;
   margin-top: -250px;
}

/*@media screen and (max-width: 1080px) {
   .main-box{
      padding-top: 50%;
   }
}*/

@media screen and (max-width: 768px) {
   .main-box{
      padding-top: 30%;
   }

   .main-box-for-semicerchio{
      margin-top: -290px;
   }
}

.custom-file-label::after{
   content: "Scegli" !important;
}

.accordion_content{
   position: relative;
}

.accordion_content-resize{
   height: 254px;
   overflow: auto;
}

.custom_scroll::-webkit-scrollbar {
   width: 5px;               /* width of the entire scrollbar */
}
 
.custom_scroll::-webkit-scrollbar-track {
   background: rgba(124, 124, 124, 0.486);        /* color of the tracking area */
   border-radius: 25px;
}
 
.custom_scroll::-webkit-scrollbar-thumb {
   background-color: #3300ff;    /* color of the scroll thumb */
   border-radius: 25px;       /* roundness of the scroll thumb */
   /* border: 3px solid orange; */  /* creates padding around scroll thumb */
}

.dropdown-item:active{
   background-color: #3300ff;
}

.racemood-brand-font{
   font-family: 'Racing Sans One', cursive;
}

.center-brand {
   transform: translateX(-50%);
   left: 50%;
   position: absolute;
}

@media screen and (max-width: 768px) {
   .center-brand {
      transform: none;
      left: unset;
      padding-left: 5px;
   }
}

.header-menu-margins {
   margin-right: auto;
}

@media screen and (max-width: 768px) {
   .header-menu-margins {
      margin-left: auto;
   }
}

@media screen and (max-width: 768px) {
   .user-header-menu {
      position: absolute;
      right: 0;
   }
}

.guest-user-menu {
   padding: 10px;
   font-size: 25px;
   position: absolute;
   right: 0;
   top: 5px;
}

.guest-user-menu > a {
   color: black;
}

.form-title {
   text-align: center;
}

.like-button{
   opacity: 0;
   position: absolute;
}

.form-control:focus {
   border-color: #3300ff;
}

.adv{
   background-color: #adff2fad;
}

#for-adv {
   margin: 0;
}

.footer {
   bottom: 0;
   width: 100%;
   height: 100%;
   line-height: 30px;
   background-color: #1D1D1DFF;
   border-top-left-radius: 50% 50px;
   border-top-right-radius: 50% 50px;
}

.footer-titles {
   font-size: 20px;
   font-weight: 700;
   line-height: 1.2;
   color: #fff;
}

.footer-container {
   display: flex;
   flex-wrap: wrap;
}

.footer-item {
   width: 100%
}

.footer-container > div {
   flex: 50%;
}

.footer a {
   padding: 0;
}

.socials {
   font-size: 20px;
}

.item-centered {
   display: flex;
   justify-content: center;
}

.footer-font-color {
   color: #ffbf00 !important;
}

.closed-card {
   max-height: 33px;
   overflow: hidden;
}

.opened-card {
   overflow: auto;
   max-height: 228px;
}

.btn-link.btn:focus {
   box-shadow: none;
}

.menu-active {
   background-color: #3300ff;
   width: 100%;
   height: 4px;
   margin-top: 4px;
   border-radius: 10px;
}

.banner_to_sell {
   margin-top: 25px;
   display: flex;
   width: 100%;
   border: 3px solid;
   border-color: greenyellow;
   background-color: rgba(172, 255, 47, 0.267);
   border-radius: 30px;
   justify-content: center;
   word-break: break-all;
   line-height: 1;
}

.breakline {
   height: 30px;
}

.banner_to_sell_content {
   margin: 50px 0 50px 0;
   line-height: 20px;
   text-transform: uppercase;
   font-weight: bold;
   text-align: center;
}

.octane-like {
   width: 210px;
}

.btn-primary {
   color: #fff;
   background-color: #3300ff;
   border-color: #3300ff;
}

.btn-primary:hover {
   color: #fff;
   background-color: #3300ff;
   border-color: #3300ff;
}

.disc-name-color {
   color: #3300ff;
}

.title-login {
   font-size: 25px;
   text-align: center;
   color: #afafaf;
}

.subtitle-login {
   font-size: 15px;
   color: grey;
   text-align: center;
}

.footer-help {
   margin-top: -7px;
}

.heading-404 {
   font-size: 50px;
   text-align: center;
}

.image404-wrapper {
   margin: 70px 0;
}

a:hover {
   text-decoration: none;
}
 
.video-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0,0,0,.4);
}

.accessForm .form-control::placeholder {
   color: #ffbf00dc;
}

#backVideo {
   position: fixed;
   width: 100%;
}

@media screen and (max-width: 992px){
   #backVideo {
      position: fixed;
      width: auto;
      height: 100%;
   }
}

@media screen and (max-width: 768px){
   #backVideo {
      position: fixed;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate(-41%, -50%);
   }
}

/* 
* puts image background on login (check register) 
@media screen and (max-width: 768px){
   .main {
      background-image: url('../../images/bg-image-light.png');
      height: 100%;
      background-position: center;
      background-size: cover;
      position: fixed;
   }
} */

.alert-danger,
.alert-success {
   padding: 10px 15px;
   margin: 13px 0px;
}

.alert-danger {
   background-color: #f8d7dac2;
   border-color: #f5c6cb94;
}

.potential-container {
   font-family: 'Racing Sans One', cursive;
   margin-bottom: 15px;
}

.display-cv { 
   display: flex;
   align-items: center;
   justify-content: center;
   color: #07c11e;
   font-size: 30px;
   line-height: 25px;
}

.cv-title {
   display: flex;
   justify-content: center;
   margin-top: 20px;
   font-size: 25px;
   gap: 5px;
}

.info-cv-icon {
   font-size: 13px;
   color: grey;
}

.cv-font {
   color: #07c11e;
   font-size: 30px;
}

.category-mod-item {
   width: 50%;
   color: grey;
   padding: 10px;
}
 
.mods-container {
   display: flex;
   flex-wrap: wrap;
}

.mod-item {
   color: black;
   display: list-item;
   list-style: circle;
}

.ul-mods {
   padding: revert;
   display: grid;
}

.cat-logos-size {
   width: 30px;
   height: 30px;
   margin-right: 10px;
}

.mod-category-title {
   display: flex;
   align-items: center;
}

.supplier-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 20px;
}

.supplier-name {
   font-family: 'Racing Sans One';
   font-size: 30px;
   line-height: 33px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.supplier-name a {
   color: black;
}

.divider {
   width: 50%;
   border-top: 1px solid #dee2e6;
}

.separator {
   display: flex;
   align-items: center;
   text-align: center;
   width: 50%;
}
 
.separator::before,
.separator::after {
   content: '';
   flex: 1;
   border-bottom: 1px solid black;
}

.separator:not(:empty)::before {
   margin-right: 10px;
}

.separator:not(:empty)::after {
   margin-left: 10px;
}

.username-menu {
   max-width: 230px;
   display: flex;
   flex-direction: row;
   align-items: center;
}

@media screen and (max-width: 768px) {
   .username-menu {
      max-width: 110px;
   }
}

.username {
   overflow: hidden;
   text-overflow: ellipsis;
}

.wrapper-stima-cv {
   margin-right: 20px;
}

@media screen and (max-width: 768px) {
   .wrapper-stima-cv {
      margin-right: 2px;
   }
}

@media screen and (max-width: 768px) {
   .btn-stima-cv {
      font-size: 15px;
   }
}

.approver-actions {
   display: flex;
   margin: 15px 0;
   gap: 10px;
   justify-content: center;
}

.styles-module_tooltip__mnnfp {
   width: 70% !important;
}

.styles-module_tooltip__mnnfp li {
   display: list-item;
   list-style: disc;
   padding: 0;
}

.styles-module_tooltip__mnnfp ul {
   padding-left: 28px;
}

.insta_name_wrapper{
   display: flex;
   justify-content: center;
}

.insta_name_input {
   width: 60%;
   margin-top: 20px;
}

.pad_top_404 {
   padding-top: 100px;
}

.semicerchio {
   background-color: #ffbf00;
   width: 100%;
   height: 400px;
   border-bottom-left-radius: 50% 100px;
   border-bottom-right-radius: 50% 100px;
   position: relative;
}

select {
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-login-wrapper {
   display: flex;
   justify-content: center;
}

/* FIX ALL'APERTURA MODALI SI SPOSTAVANO ALCUNI OGGETTI*/
body {
   overflow: auto !important;
   padding: 0 !important;
}

nav.navbar.fixed-top {
   padding-right: 1rem !important;
}

@media screen and (max-width: 768px) {
   nav.navbar.fixed-top {
      padding-right: 3px !important;
   }
}

/* FINE FIX*/

.mods-container.empty {
   display: flex;
   justify-content: center;
}

.login-to-show-elab {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
}

.btn-as-link {
   background: none;
   border: none;
}

.hp-title {
   font-weight: 300;
   font-size: 30px;
}

.hp-title-bold {
   font-weight: 500;
}

.hp-subtitle {
   font-weight: 400;
}

@media screen and (max-width: 768px) {
   body {
      font-family: unset;
   }

   .dropdown-menu-right {
      right: 3px;
   }
}
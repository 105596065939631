.stages-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-top: 30px;
}

.single-stage-container {
    width: 100%;
}

.mods-list {
    padding-left: 10px;
    padding-right: 10px;
}

.table-text-align {
    text-align: center;
    vertical-align: middle !important;
}

.elab-container {
    border: 1px solid #8080805e;
    border-radius: 5px;
    padding: 0 15px 15px 15px;
    margin-top: 20px;
}